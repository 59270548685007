<template>
  <div class="w-100">
    <div class="title-actions">
      <h5 class="title">{{ tournament.name }} - {{ $t('rules') }}</h5>
      <div class="btns">
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <b-form @submit.prevent="save">
      <div class="form-group">
        <label for="content">{{$t('content')}}</label>
        <quill-editor ref="myTextEditor" name="content" v-model="content" :options="editorOption" v-validate="'required'" :state="validateState('quantity')"></quill-editor>
        <span class="error-inputs">{{ errors.first('content') }}</span>
      </div>
      <b-button @click="$router.go(-1)" class="mr-3">{{ $t('cancel') }}</b-button>
      <b-button type="submit" variant="primary">{{ $t('save') }}</b-button>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      content: '',
      editorOption: {
        modules: {
          toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'script': 'sub' }, { 'script': 'super' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'align': [] }],
              ['clean'],
              ['link', 'image', 'video']
            ],
           imageDrop: true,
           imageResize: true
         },
         theme: 'snow'
      }
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'tournament',
      'console'
    ])
  },
  mounted() {
    if (!this.tournament.name) {
      this.$router.push({ name: 'Tournaments', params: { lang: this.lang, console: this.console } })
      return
    }
    this.changeTitleBar(this.tournament.name + ' - ' + this.$t('rules'))
    this.fetchData()
  },
  methods: {
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          const payload = {
            content: this.content
          }
          const tournamentId = this.$route.params.tournamentId
          const path = `auth/${this.lang}/${this.console}/tournament/${tournamentId}/rules/store`
          this.$axios.post(path, payload).then(() => {
            this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
            this.$router.go(-1)
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    fetchData () {
      const tournamentId = this.$route.params.tournamentId
      const path = `auth/${this.lang}/${this.console}/tournament/${tournamentId}/rules`
      this.$axios.get(path).then(response => {
        this.content = response.data.data
      })
    }
  }
}
</script>
<style>
.ql-editor{
  min-height: 200px;
}
</style>
